const supportsWebPKey = Symbol.for('isWebPSupported');

export class BrowserDetectionService {
  static async detectWebPSupport(): Promise<void> {
    const image = new Image();
    image.src = 'data:image/webp;base64,UklGRiIAAABXRUJQVlA4IBYAAAAwAQCdASoBAAEADsD+JaQAA3AAAAAA';
    try {
      await image.decode();
      (window as any)[supportsWebPKey] = image.width > 0 && image.height > 0;
      // eslint-disable-next-line no-unused-vars
    } catch (error) {
      (window as any)[supportsWebPKey] = false;
    }
  }

  static isWebPSupported(): boolean {
    return this.isBrowserContext() ? (window as any)[supportsWebPKey] : true;
  }

  static isBrowserContext(): boolean {
    return typeof window !== 'undefined';
  }
}
